exports.components = {
  "component---src-pages-chapter-2-js": () => import("./../../../src/pages/chapter-2.js" /* webpackChunkName: "component---src-pages-chapter-2-js" */),
  "component---src-pages-chapter-3-js": () => import("./../../../src/pages/chapter-3.js" /* webpackChunkName: "component---src-pages-chapter-3-js" */),
  "component---src-pages-chapter-4-js": () => import("./../../../src/pages/chapter-4.js" /* webpackChunkName: "component---src-pages-chapter-4-js" */),
  "component---src-pages-chapter-5-js": () => import("./../../../src/pages/chapter-5.js" /* webpackChunkName: "component---src-pages-chapter-5-js" */),
  "component---src-pages-chapter-6-js": () => import("./../../../src/pages/chapter-6.js" /* webpackChunkName: "component---src-pages-chapter-6-js" */),
  "component---src-pages-chapter-7-js": () => import("./../../../src/pages/chapter-7.js" /* webpackChunkName: "component---src-pages-chapter-7-js" */),
  "component---src-pages-dev-js": () => import("./../../../src/pages/dev.js" /* webpackChunkName: "component---src-pages-dev-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

